import React from 'react';

const SvgComponent = props => (
  <svg width="100%" viewBox="0 0 454 243" {...props}>
    <defs>
      <path id="prefix__a" d="M0 0h456v252H0z" />
    </defs>
    <g transform="translate(0 -5)" fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <g mask="url(#prefix__b)">
        <g transform="translate(0 -32)">
          <ellipse
            fill="#E7E7E7"
            cx={84.614}
            cy={267.209}
            rx={84.614}
            ry={12.362}
          />
          <ellipse
            fill="#E7E7E7"
            cx={346.062}
            cy={271.963}
            rx={45.634}
            ry={7.607}
          />
          <path
            d="M49.86 183.922s-8.23 40.93-23.535 50.615c0 0 39.377 22.785 53.675 23.419l-30.14-74.034z"
            stroke="#414141"
            strokeWidth={2.28}
            fill="#6B6B6B"
            fillRule="nonzero"
            strokeLinejoin="round"
          />
          <path
            d="M55.176 166.02s-26.255 9.29-25.602 18.777c1.052 14.713 30.901-1.988 30.901-1.988l-5.3-16.789z"
            stroke="#414141"
            strokeWidth={2.28}
            fill="#CACACA"
            fillRule="nonzero"
          />
          <path
            d="M51.187 165.358l-14.39 9.28s5.66 11.74 7.88 16.932c0 0 12.828-2.912 17.903-6.863l-11.393-19.35z"
            stroke="#414141"
            strokeWidth={2.28}
            fill="#6B6B6B"
            fillRule="nonzero"
            strokeLinejoin="round"
          />
          <path
            d="M168.01 166.067s16.99-13.534 23.351-11.027c11.974 4.75-11.65 25.063-11.65 25.063l-11.7-14.036z"
            stroke="#414141"
            strokeWidth={2.28}
            fill="#CACACA"
            fillRule="nonzero"
          />
          <path
            d="M168.204 166.608l11.93-8.287s6.477 10.72 10.007 15.123c0 0-9.98 11.46-15.933 14.095l-6.004-20.93z"
            stroke="#414141"
            strokeWidth={2.28}
            fill="#6B6B6B"
            fillRule="nonzero"
            strokeLinejoin="round"
          />
          <path
            d="M113.174 159.161s1.767 15.233 8.834 14.806c6.982-.437 5.14-15.765 5.14-15.765"
            stroke="#414141"
            strokeWidth={4.397}
            fill="#FFF"
            fillRule="nonzero"
          />
          <g transform="rotate(7 -96.296 2421.135)">
            <ellipse
              stroke="#414141"
              strokeWidth={1.824}
              fill="#777"
              fillRule="nonzero"
              transform="rotate(-15.201 84.61 63.775)"
              cx={84.611}
              cy={63.775}
              rx={82.757}
              ry={17.745}
            />
            <path
              d="M79.617 46.63c35.677-9.662 67.238-12.882 79.382-8.702L69.463.308c-1.647-.685-3.5-.206-4.529 1.233L5.724 79.727c-.343.412-.412.891-.412 1.37 5.97-9.798 36.226-24.12 74.305-34.467z"
              stroke="#414141"
              strokeWidth={1.824}
              fill="#BABABA"
              fillRule="nonzero"
            />
            <path
              d="M65.14 1.521c-.068.069-.137.138-.206.275L5.724 79.982c-.343.411-.412.89-.412 1.37 3.43-5.619 14.82-12.745 31.08-19.735L65.14 1.521z"
              fill="#9C9C9C"
              fillRule="nonzero"
            />
            <path
              d="M79.617 46.63c35.677-9.662 67.238-12.882 79.382-8.702L69.463.308c-1.647-.685-3.5-.206-4.529 1.233L5.724 79.727c-.343.412-.412.891-.412 1.37 5.97-9.798 36.226-24.12 74.305-34.467z"
              stroke="#414141"
              strokeWidth={1.824}
            />
          </g>
          <path
            d="M240.347 153.064c3.768-11.066 10.951-17.534 21.55-19.403 15.896-2.804 43.5 17.354 58.427-1.595"
            stroke="#414143"
            strokeWidth={0.95}
            strokeDasharray="3.8 1.9"
          />
          <g fillRule="nonzero">
            <path
              d="M76.03 87.375c-.632-1.2-1.435-2.42-2.44-3.406-5.527-5.77-14.76-6.041-20.63-.548-5.785 5.503-6.049 14.704-.533 20.56a12.961 12.961 0 0 0 3.323 2.565l20.28-19.171z"
              stroke="#414141"
              strokeWidth={2.28}
              fill="#CACACA"
            />
            <path
              d="M68.512 90.767c-.279-.552-.653-1.03-1.113-1.517a6.533 6.533 0 0 0-9.147-.26 6.48 6.48 0 0 0-.254 9.116c.46.488.93.89 1.506 1.134l9.008-8.473z"
              fill="#414141"
            />
          </g>
          <g fillRule="nonzero">
            <path
              d="M134.992 86.396c.252-1.35.59-2.69 1.269-3.987 3.488-7.252 12.169-10.242 19.449-6.76 7.28 3.484 10.287 12.137 6.799 19.39-.594 1.308-1.433 2.5-2.336 3.51l-25.181-12.153z"
              stroke="#414141"
              strokeWidth={2.28}
              fill="#CACACA"
            />
            <path
              d="M143.278 87.327c.073-.595.317-1.17.56-1.744 1.516-3.179 5.404-4.514 8.596-3 3.191 1.514 4.534 5.39 3.018 8.569a4.906 4.906 0 0 1-1.052 1.51l-11.122-5.335z"
              fill="#414141"
            />
          </g>
          <path
            d="M100.89 76.918c17.104-2.474 58.296.213 68.022 42.313 8.594 37.214 14.362 110.845-22.426 128.334-36.788 17.49-73.45 5.739-86.946-10.59-13.497-16.327-6.51-51.37-7.813-68.962-1.304-17.592-6.48-56.545 14.963-75.314 10.285-9.006 17.095-13.307 34.2-15.78z"
            stroke="#414141"
            strokeWidth={2.28}
            fill="#CACACA"
            fillRule="nonzero"
          />
          <g fillRule="nonzero">
            <g transform="rotate(7 -697.53 1117.092)">
              <ellipse
                stroke="#414141"
                strokeWidth={2.28}
                fill="#FFF"
                cx={24.061}
                cy={24.242}
                rx={23.587}
                ry={23.499}
              />
              <g transform="translate(21.112 15.572)">
                <ellipse
                  fill="#414141"
                  cx={9.325}
                  cy={9.305}
                  rx={8.767}
                  ry={8.737}
                />
                <ellipse
                  fill="#FFF"
                  cx={12.219}
                  cy={10.961}
                  rx={3.094}
                  ry={3.084}
                />
              </g>
            </g>
            <g transform="rotate(7 -754.52 583.362)">
              <ellipse
                stroke="#414141"
                strokeWidth={2.28}
                fill="#FFF"
                cx={25.529}
                cy={25.429}
                rx={25.013}
                ry={24.926}
              />
              <g transform="translate(23.711 14.288)">
                <ellipse
                  fill="#414141"
                  cx={9.367}
                  cy={9.332}
                  rx={8.767}
                  ry={8.737}
                />
                <ellipse
                  fill="#FFF"
                  cx={12.261}
                  cy={10.5}
                  rx={3.094}
                  ry={3.084}
                />
              </g>
            </g>
          </g>
          <path
            d="M131.565 152.517c-.462 11.197-4.01 16.478-10.644 15.844-6.634-.634-9.308-6.182-8.02-16.643l10.74-3.642 7.924 4.441z"
            fill="#414141"
          />
          <path
            d="M125.169 164.551c-1.067.8-2.376 1.124-3.924.972-1.486-.146-2.687-.655-3.603-1.528.78-.483 2.074-.785 3.533-.76 1.843.033 3.405.579 3.994 1.316z"
            fill="#6B6B6B"
          />
          <path
            d="M115.286 147.075s.806 12.754 8.523 12.55c6.925-.17 6.702-11.778 6.702-11.778l-15.225-.772zM124.192 159.431l-.882-13.81"
            stroke="#414141"
            strokeWidth={2.28}
            fill="#FFF"
            fillRule="nonzero"
          />
          <path
            d="M114.418 155.007c.478.258 1.016.179 1.484 0 1.319-.55 5.047-1.893 7.73-1.767 3.207.13 5.388 1.124 6.275 1.541.393.244.886.417 1.324.408 1.992.09 3.12-2.842 3.013-5.21-.167-3.508-2.753-6.66-9.49-7.066-10.012-.635-12.513 4.143-12.505 8.058-.069 1.38 1.198 3.604 2.169 4.036z"
            stroke="#414141"
            strokeWidth={2.28}
            fill="#BABABA"
            fillRule="nonzero"
          />
          <path
            d="M122.14 137.327c5.597-.057 7.24 1.016 7.696 3.88.375 2.327-2.704 5.437-10.826 3.483-6.529-1.586-3.426-7.302 3.13-7.363z"
            fill="#414141"
            fillRule="nonzero"
          />
          <path
            d="M51.71 201.296s-3.217 49.478-6.394 52.074c0 0 28.595 16.093 69.87 16.363l2.314-30.128-65.79-38.309z"
            stroke="#414141"
            strokeWidth={2.28}
            fill="#FFF"
            fillRule="nonzero"
            strokeLinejoin="round"
          />
          <path
            d="M168.494 216.438l-6.096 49.65s-19.74 5.602-47.474 3.664l2.398-28.702 51.172-24.612z"
            stroke="#414141"
            strokeWidth={2.28}
            fill="#FFF"
            fillRule="nonzero"
            strokeLinejoin="round"
          />
          <path
            d="M174.885 195.91c-.74-5.959.431-31.014.431-31.014-16.292 15.604-48.375 18.051-48.375 18.051l-4.449 10.845-7.41-8.763c-36.316 2.359-63.66-21.797-63.66-21.797s-1.048 27.574.44 33.797c2.826 12.947 15.67 60.694 35.208 63.783 23.358 3.731 66.59 4.293 96.926-9.328-.074-.096-8.285-37.608-9.11-55.573z"
            stroke="#414141"
            strokeWidth={2.28}
            fill="#6B6B6B"
            fillRule="nonzero"
            strokeLinejoin="round"
          />
          <path
            stroke="#414141"
            strokeWidth={2.28}
            fill="#EAEAEA"
            fillRule="nonzero"
            strokeLinejoin="round"
            d="M122 203.653l5.166 10.127 12.147 1.923-9.138 7.422 1.766 11.004-10.76-5.464-11.064 4.77 2.455-10.832-8.624-8.049 12.268-1.169z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;
